import { Link } from "gatsby";
import React, {useContext} from "react";
import LogoIsidoro from "../../assets/img/svg/logo-isidoro.svg";
import LogoRoomCloud from "../../assets/img/svg/logo-roomcloud.svg";
import { ThemeContext } from "../../context/ThemeContext";
import "./style.scss";

const Footer = () => {
  const theme = useContext(ThemeContext);

  function toggleMenu() {
    theme.setMenuOpen(!theme.menuOpen);
  }

  return (
    <footer>
      <div className="inner-footer">
        <div className="columns is-multiline m-0">
          <div className="column is-offset-1-tablet is-2-tablet contacts">
          <button onClick={toggleMenu}>Menu</button>
            <br />
            <br />
            <Link to="/le-nostre-integrazioni">Le nostre integrazioni</Link>
            <br />
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="http://docs.acmeproduzioni.it/bam-website/privacy-policy-it.html"
            >
              Privacy Policy
            </a>
          </div>
          <div className="column is-2-tablet pages">
            <Link to="/perchè-book-at-me">Perché Book@Me</Link>
            <br />
            <br />
            <Link to="/i-nostri-clienti">
              I nostri clienti
            </Link>
            <br />
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="http://docs.acmeproduzioni.it/bam-website/cookie-policy-it.html"
            >
              Cookie Policy
            </a>
            {/* <Link to="/blog">Blog &amp; News</Link> */}
          </div>
          <div className="column is-2-tablet social">
            <Link to="/contatti">Contatti</Link>
            <br />
            <br />
            <Link to="/funzionalità">Funzionalità</Link>
            {/* <a
              target="_blank"
              rel="noreferrer"
              href="/"
            >
              Login
            </a> */}
          </div>
          <div className="column is-offset-2-tablet is-offset-2-desktop is-8-tablet is-3-desktop">
            <div className="columns is-gapless">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/acmeproduzioni/"
                className="column is-3 is-full-mobile"
              >
                <span className="green">Facebook</span>
              </a>
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/acmeproduzioni_official/"
                className="column is-9 is-full-mobile"
              >
                <span className="green">Instagram</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <div className="columns m-0">
          <div className="column is-offset-1-tablet is-8-tablet ">
            <span>
              © 2013-{theme.currentYear} Book@Me® by ACME Produzioni Srl - PIVA/VAT 10245151005
            </span>
          </div>
          <div className="column is-2-tablet ">
            <div className="columns is-mobile">
              <div className="column">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://isidorosoftware.com/"
                  className=""
                >
                  <img src={LogoIsidoro} alt="Logo Isidoro" />
                </a>
              </div>
              <div className="column">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.roomcloud.net/"
                  className=""
                >
                  <img src={LogoRoomCloud} alt="Logo RoomCloud" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
