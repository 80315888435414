// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-engine-js": () => import("./../../../src/pages/booking-engine.js" /* webpackChunkName: "component---src-pages-booking-engine-js" */),
  "component---src-pages-channel-manager-js": () => import("./../../../src/pages/channel-manager.js" /* webpackChunkName: "component---src-pages-channel-manager-js" */),
  "component---src-pages-checkin-online-js": () => import("./../../../src/pages/checkin-online.js" /* webpackChunkName: "component---src-pages-checkin-online-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-functionalities-js": () => import("./../../../src/pages/functionalities.js" /* webpackChunkName: "component---src-pages-functionalities-js" */),
  "component---src-pages-hospitality-js": () => import("./../../../src/pages/hospitality.js" /* webpackChunkName: "component---src-pages-hospitality-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ota-js": () => import("./../../../src/pages/ota.js" /* webpackChunkName: "component---src-pages-ota-js" */),
  "component---src-pages-our-customers-js": () => import("./../../../src/pages/our-customers.js" /* webpackChunkName: "component---src-pages-our-customers-js" */),
  "component---src-pages-our-integrations-js": () => import("./../../../src/pages/our-integrations.js" /* webpackChunkName: "component---src-pages-our-integrations-js" */),
  "component---src-pages-revenue-management-js": () => import("./../../../src/pages/revenue-management.js" /* webpackChunkName: "component---src-pages-revenue-management-js" */),
  "component---src-pages-why-bam-js": () => import("./../../../src/pages/why-BAM.js" /* webpackChunkName: "component---src-pages-why-bam-js" */)
}

