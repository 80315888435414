import React, { useContext } from "react";
import { Link } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import { ThemeContext } from "../../context/ThemeContext";

import "./style.scss";

const Menu = ({ open }) => {
  const theme = useContext(ThemeContext);

  function closeMenu() {
    theme.setMenuOpen(false);
  }

  function toggleSubMenu() {
    theme.setSubMenuOpen(!theme.subMenuOpen);
  }

  const MENUITEMS = [
    { item: "Moduli", to: "/", externalLink: false, mobileMenuItem: false, subMenu: true},
    { item: "Le nostre integrazioni", to: "/le-nostre-integrazioni", externalLink: false, mobileMenuItem: false, subMenu: false},
    { item: "Funzionalità", to: "/funzionalità", externalLink: false, mobileMenuItem: false, },
    { item: "Perché Book@Me", to: "/perchè-book-at-me", externalLink: false, mobileMenuItem: false, subMenu: false},
    { item: "I nostri clienti", to: "/i-nostri-clienti", externalLink: false, mobileMenuItem: false, subMenu: false},
    // { item: "Blog & News", to: "/blog", externalLink: false, mobileMenuItem: false, subMenu: false},
    { item: "Contatti", to: "/contatti", externalLink: false, mobileMenuItem: false, subMenu: false},
    // { item: "Login", to: "/", externalLink: true, mobileMenuItem: true, subMenu: false},
  ];

  const SUBMENUITEMS = [
    { item: "Booking Engine", to: "/booking-engine" },
    { item: "Channel Manager", to: "/channel-manager" },
    { item: "Revenue Management", to: "/revenue-management" },
    { item: "CheckIN Online", to: "/checkin-online" },
  ];

  const variants = {
    menuAnimation: {
      open: {
        width: theme.isMobile ? "100%" : "35%",
        transition: { duration: 0.3 },
      },
      closed: { width: "0px", transition: { delay: 0.2, duration: 0.5 } },
    },
    menuItemAnimation: {
      init: { x: 100, opacity: 0 },
      open: { x: 0, opacity: 1 },
      closed: { x: 100, opacity: 0, transition: { delay: 0.1, duration: 0.2 } },
    },
  };

  const menuItem = (i, index) => (
    <motion.div
      animate={open ? "open" : "closed"}
      variants={variants.menuItemAnimation}
      transition={{ delay: 0.1 * index, duration: 0.3 }}
      className="menuItem"
    >
      {i.externalLink ? (
        <a target="_blank" rel="noreferrer" href={i.to}>
          {i.item}
        </a>
      ) : (
        <>
          {!i.subMenu && (
            <Link to={i.to} onClick={closeMenu}>
              {i.item}
            </Link>
          )}
          {i.subMenu && (
            <>
              <button className="itemWithSubMenu" onClick={toggleSubMenu}>
                {i.item}&nbsp;
                <div className="iconCircle">
                  {theme.subMenuOpen && <div className="iconTriangleTop" />}
                  {!theme.subMenuOpen && <div className="iconTriangleBottom" />}
                </div>
              </button>
              {theme.subMenuOpen && (
                <div className="subMenu">
                  {SUBMENUITEMS.map((i, index) => (
                    <AnimatePresence exitBeforeEnter key={index}>
                      <motion.div
                        initial={"init"}
                        animate={theme.subMenuOpen ? "open" : "closed"}
                        variants={variants.menuItemAnimation}
                        transition={{ delay: 0.1 * index, duration: 0.3 }}
                        className="subMenuItem"
                      >
                        <Link to={i.to} onClick={closeMenu}>
                          {i.item}
                        </Link>
                      </motion.div>
                    </AnimatePresence>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
    </motion.div>
  );

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      variants={variants.menuAnimation}
      className="menu"
    >
      {MENUITEMS.map((i, index) => (
        <AnimatePresence exitBeforeEnter key={index}>
          {!i.mobileMenuItem && menuItem(i, index)}
          {theme.isMobile && i.mobileMenuItem && menuItem(i, index)}
        </AnimatePresence>
      ))}
    </motion.div>
  );
};

export default Menu;
